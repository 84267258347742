<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Catálogos</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="catalogos" @ready="buscar_btns">
      <button class="btn btn-secondary mr-2" @click="obtener_catalogos">Recargar</button>
      <button class="btn btn-primary" @click="show_modal=true">Nuevo</button>
    </DataGrid>

    <Formulario v-if="show_modal" :catalogo="catalogo" @close="cerrar_modal" @update="cerrar_modal(true)" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';

import Formulario from './Catalogo/Form';

import api from '@/apps/analyticpoint/api/comite/catalogos';

export default {
  components: {
    DataGrid, Formulario
  },
  data() {
    return {
      datagrid_config: {
        cols: {
          nombre: 'Nombre',
          codigo: 'Código',
          descripcion: 'Descripción',
          estatus: 'Estatus',
          opciones: 'Opciones'
        },
        mutators: {
          estatus(val) {
            return val == 1 ? 'Sí' : 'No';
          },
          opciones(va, row, vue) {
            return '<button class="btn btn-secondary mr-2 btn-accion" data-catalogo="'+vue.$helper.toJson(row)+'" data-accion="editar"><i class="fa-regular fa-pen-to-square"></i></button>'
                    + '<button class="btn btn-secondary btn-accion" data-catalogo="'+vue.$helper.toJson(row)+'" data-accion="eliminar"><i class="fa-solid fa-trash-can"></i></button>';
          }
        },
        search: ['nombre','codigo','estatus'],
        selector: false
      },
      catalogos: [],
      catalogo: null,
      show_modal: false
    }
  },
  mounted() {
    this.obtener_catalogos();
  },
  methods: {
    async obtener_catalogos() {
      try {
        this.catalogos = (await api.obtener_catalogos()).data;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('button.btn-accion').forEach(btn => {
        btn.removeEventListener('click',this.abrir_modal);
        btn.addEventListener('click',this.abrir_modal);
      });
    },
    abrir_modal(e) {
      let btn = e.target;

      if (btn.tagName == 'I')
        btn = btn.parentElement;

      this.catalogo = this.$helper.fromJson(btn.dataset.catalogo);
      this.show_modal = true;
    },
    cerrar_modal(recargar) {
      if (typeof recargar == 'undefined')
        recargar = false;

      this.catalogo = null;
      this.show_modal = false;

      if (recargar)
        this.obtener_catalogos();
    }
  }
}
</script>